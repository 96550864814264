@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 767px) {
  .Carousel__ItemSet-sc-hyhecw-3 {
    width: fit-content;
  }
}

@media only screen and (max-width: 600px) {
    .new-class {
        overflow-y: hidden;
    }
  }
  .fading-div {
    border-radius: 10px;
    width: 100%;
    position: relative;
    background: linear-gradient(transparent 0%, black 100%);
  }

  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
      @media screen and (max-width: 600px) {
        .safari_only {
          max-height: 10%;
        }
      }
      @media screen and (min-width: 601px) {
        .safari_only { 
          max-height: 22%;
        }
      }
  }}
    @media screen and (max-width: 600px) {
      .safari_only {
        max-height: 10%;
      }
    }
    @media screen and (min-width: 601px) {
      .safari_only { 
        max-height: 22%;
      }
    }
  
.scroll-display{
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-display::-webkit-scrollbar {
  display: none;
}
