.cover-image-container {
    position: relative;
    width: 100%;
    height: 82vh;
    overflow: hidden;
    /* Prevent content overflow */
}

.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    display: block;
}


.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(7, 28, 9, 0.9) 0%, rgba(33, 130, 42, 0.072) 100%);
    z-index: 1;
    /* Ensure overlay stays under the text */
}

.cover-text-container {
    position: absolute;
    top: 10%;
    left: 5%;
    transform: translateX(-50px);
    padding: 15px;
    border-radius: 10px;
    max-width: 75%;
    line-height: 1.5;
    animation: slideIn 1.5s ease-out forwards;
    color: white;
    font-size: 3.5rem;
    font-weight: 600;
    text-align: left;
    z-index: 2;
}

.cover-text {
    margin-bottom: 2rem;
}

.yellow-bold-line {
    border-top: 5px solid #DAFF0F;
    width: 60%;
}

.data-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
}

@keyframes slideIn {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Media Queries for responsiveness */

@media (max-width: 1280px) {
    .cover-text-container {
        font-size: 2.5rem;
        top: 2%;
        left: 2%;
        max-width: 100%;
    }

    .cover-text {
        font-size: 2rem;
    }

    .yellow-bold-line {
        width: 50%;
    }

    .cover-text-container {
        padding: 10px;
    }
}

@media (max-width: 1024px) {
    .cover-text-container {
        font-size: 2.5rem;
        top: 15%;
        left: 10%;
        max-width: 85%;
    }

    .cover-text {
        font-size: 2rem;
    }

    .yellow-bold-line {
        width: 50%;
    }

    .cover-text-container {
        padding: 10px;
    }
}

@media (max-width: 768px) {
    .cover-text-container {
        font-size: 2rem;
        top: 20%;
        left: 5%;
        max-width: 90%;
    }

    .cover-text {
        font-size: 1.6rem;
    }

    .yellow-bold-line {
        width: 40%;
    }

    .cover-text-container {
        padding: 8px;
    }

    .cover-text-container .cover-text {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .cover-image-container {
        height: 60vh;
    }
    
    .cover-text-container {
        font-size: 1.6rem;
        top: 25%;
        left: 5%;
        max-width: 95%;
       
    }

    .cover-text {
        font-size: 1.4rem;
    }

    .yellow-bold-line {
        width: 30%;
    }

    .cover-text-container {
        padding: 6px;
    }

    .cover-text-container .cover-text {
        font-size: 1.4rem;
        text-align: center;
    }

    .data-div{
        width: 100%;
    }

    .data-div p{
        font-size: 0.875rem;
    }
}
