/* Base styles */
.about-us {
    background-color: #FFFFFF;
    padding: 6rem 5rem;
}

.about-us-container {
    background: #FFFFFF;
    width: 90%;
    margin: auto;
}

.border-green {
    border: 1px solid #D7F4D7;
    border-radius: 6px;
    border-width: 1px;
}

.our-mission-div,
.our-vision-div {
    display: flex;
}

.our-mission-text,
.our-vision-text {
    padding: 3rem;
    width: 60%;
}

.our-mission-text h2,
.our-vision-text h2 {
    color: #F88900;
    font-weight: 700;
    font-size: 4rem;
    line-height: 90px;
    letter-spacing: 0%;
}

.our-mission-text p,
.our-vision-text p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    letter-spacing: 0%;
    color: #727272;
}

.our-mission-img img,
.our-vision-img img {
    position: relative;
    top: 0%;
}

.our-mission-img img {
    left: 15%;
}

.our-vision-img img {
    right: 15%;
}

.we-are-div {
    border-width: 1px;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #D7F4D7;
    backdrop-filter: blur(4px);
    height: 30rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 400px;
}

.we-are-text {
    width: 100%;
    padding: 0 5rem 5rem 5rem;
    text-align: left;
}

.we-are-text h2 {
    color: #F88900;
    font-weight: 700;
    font-size: 4rem;
    line-height: 90px;
    letter-spacing: 0%;
}

.we-are-text p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    letter-spacing: 0%;
    color: #727272;
}

.we-are-img-div {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 76%;
    right: 4%;
}

.we-are-img {
    padding: 3rem;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 10px 50px 0px #0000001A;
    width: fit-content;
}

.what-we-div {
    border-color: #D7F4D7;
    border-radius: 6px;
    border-width: 1px;
    height: 30rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 400px;
}

.what-we-text {
    width: 100%;
    padding: 0 8rem 5rem 8rem;
    text-align: right;
    margin-top: 1rem;
}

.what-we-text h2 {
    color: #F88900;
    font-weight: 700;
    font-size: 4rem;
    line-height: 90px;
    letter-spacing: 0%;
}

.what-we-text p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    letter-spacing: 0%;
    color: #727272;
}

.what-we-img-div {
    position: absolute;
    left: 12%;
}

.what-we-img {
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 10px 50px 0px #0000001A;
    width: fit-content;
}

.padding-bottom-css {
    padding-bottom: 10rem;
}

/* Responsive adjustments */
@media (max-width: 1280px) {

    .our-mission-text,
    .our-vision-text {
        padding: 1rem;
        width: 100%;
    }

    .our-mission-text h2,
    .our-vision-text h2 {
        font-size: 3rem;
        line-height: 40px;
    }

    .our-mission-text p,
    .our-vision-text p {
        font-size: 1.2rem;
    }

    .we-are-div {
        height: 21rem;
    }

    .what-we-div {
        height: 21rem;
    }

    .we-are-text h2 {
        font-size: 3rem;
    }

    .we-are-text p {
        font-size: 1.2rem;
        margin-top: 0;
    }

    .what-we-text h2 {
        font-size: 3rem;
    }

    .what-we-text p {
        font-size: 1.2rem;
        margin-top: 0;
    }

    .about-us-container {
        width: 100%;
        margin: 0;
    }

    .what-we-img img {
        height: 12rem;
    }

    .what-we-img-div {
        position: relative;
        left: 3%;
        bottom: 19%;
    }
}


@media (max-width: 768px) {

    .our-mission-text,
    .our-vision-text {
        width: 100%;
        padding: 2rem;
    }

    .our-mission-text h2,
    .our-vision-text h2 {
        font-size: 2.5rem;
        line-height: 50px;
    }

    .our-mission-text p,
    .our-vision-text p {
        font-size: 1.4rem;
    }

    .we-are-text h2 {
        font-size: 3rem;
    }

    .we-are-text p {
        font-size: 1.6rem;
    }

    .we-are-img-div {
        position: static;
        top: 0;
        right: 0;
        justify-content: center;
    }

    .what-we-text h2 {
        font-size: 3rem;
    }

    .what-we-text p {
        font-size: 1.6rem;
    }

    .what-we-img-div {
        position: static;
        bottom: 0;
        left: 0;
        justify-content: center;
    }

    .about-us-container {
        width: 95%;
    }
}



@media (max-width: 480px) {
    .about-us {
        padding: 2rem;
    }

    .our-mission-div,
    .our-vision-div {
        display: block;
    }

    .our-mission-text,
    .our-vision-text {
        padding: 1rem;
        width: 100%;
    }

    .our-mission-text h2,
    .our-vision-text h2 {
        font-size: 2rem;
    }

    .our-mission-text p,
    .our-vision-text p {
        font-size: 1.2rem;
    }

    .we-are-div {
        height: 32rem;
    }

    .we-are-text {
        width: 100%;
        padding: 1rem;
        text-align: left;
    }

    .we-are-text h2 {
        font-size: 2rem;
        line-height: 4rem;
    }

    .we-are-text p {
        font-size: 1.1rem;
        text-align: justify;
    }

    .we-are-img-div {
        position: static;
        top: 0;
        right: 0;
        justify-content: center;
    }

    .what-we-div {
        height: 34rem;
        border-radius: 1rem;
    }

    .what-we-text {
        padding: 1rem;
        margin-top: 0;
    }

    .what-we-text h2 {
        font-size: 2rem;
        line-height: 4rem;
    }

    .what-we-text p {
        font-size: 1.1rem;
        text-align: justify;
    }

    .padding-bottom-css {
        padding-bottom: 5rem;
    }

    .what-we-img-div {
        padding: 1rem;
        position: static;
        bottom: 0;
        left: 0;
        justify-content: center;
    }

    .about-us-container {
        width: 100%;
        margin: 0;
    }
}