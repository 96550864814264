.footer-nigeria-div {
    background-color: #45950c;
    padding-top: 2rem;
    padding-bottom: 5rem;
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
    font-size: 1rem;
    flex-wrap: wrap;
}

.connect-with-us-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    width: 45%;
}

.footer-img-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.footer-download-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

.footer-bottom-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 55%;
}

@media (min-width: 1024px) {
    .connect-with-us-div {
        margin-bottom: 0;
    }

    .footer-img-div {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .footer-nigeria-div {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 480px) {
    .footer-nigeria-div {
        padding: 1rem 1rem 5rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .connect-with-us-div {
        width: 100%;
    }

    .connect-with-us-div div {
        margin: auto;
    }

    .connect-header {
        font-size: 1rem;
        text-align: center;
        margin: auto;
    }

    .connect-number {
        font-size: 0.875rem;
        text-align: center;
    }


    .footer-bottom-div {
        width: 100%;
    }

    .footer-img-div {
        width: 50%;
    }

    .footer-img-div img {
        width: 80%;
    }

    .footer-download-div {
        width: 50%;
        padding-top: 1rem;
    }

    .footer-download-div p {
        font-size: 0.875rem;
    }

    .footer-download-div img {
        width: 8rem;
        height: 3rem;
    }
}