.our-services {
    background-color: #fff;
    margin-top: 8%;
}

.our-services-div {
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.our-services-data-div {
    width: 100%;
}

.our-services-content-div {
    background-color: #fff;
    width: 100%;
    border-radius: 14px;
    border: 1px solid #e5e7eb;
    padding: 1.5rem;
    margin-bottom: 1rem;
}

.our-services-content-div-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.our-services-content-div-header h1 {
    font-size: 1.5rem;
    color: #55cc00;
}

.our-services-content-text-div {
    transition: all 0.7s ease-in-out;
    transform: transform;
    overflow: hidden;
}

.our-services-content-text-div.open {
    transform: scaleY(1);
    opacity: 1;
    max-height: 1000px;
}

.our-services-content-text-div.closed {
    transform: scaleY(0);
    opacity: 0;
    max-height: 0;
}

.our-services-content-text-div p {
    margin-top: 1rem;
    font-size: 1.125rem;
}

.our-services-image-div {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 2rem;
}

.our-services-image-div img {
    width: 100%;
    max-width: 85%;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}




@media (max-width: 1280px) {
    .our-services {
        margin-top: 0%;
    }

    .our-services-content-div {
        padding: 0.5rem;
    }

    .our-services-content-div-header h1 {
        font-size: 1rem;
    }

    .our-services-content-text-div p {
        font-size: 0.875rem;
    }
}


@media (max-width: 1024px) {
    .our-services-div {
        flex-direction: row;
    }

    .our-services-data-div {
        width: 50%;
        margin-bottom: 0;
    }

    .our-services-content-div {
        width: 80%;
    }

    .our-services-image-div {
        width: 45%;
    }

    .our-services-image-div img {
        width: 100%;
        max-width: 100%;
    }
}


@media (max-width: 640px) {
    .our-services-div {
        padding: 2rem; /* Reduce padding on mobile */
    }

    .our-services-content-div {
        padding: 1rem; /* Make padding smaller on mobile */
    }

    .our-services-image-div img {
        max-width: 100%; /* Ensure the image takes up full width */
    }

    .our-services-content-div-header h1 {
        font-size: 1.25rem; /* Adjust font size for mobile */
    }

    .our-services-content-text-div p {
        font-size: 1rem; /* Adjust font size for readability */
    }

    .our-services-image-div {
        width: 100%; /* Ensure the image section takes full width */
        margin-bottom: 2rem; /* Add space between sections */
    }
}


@media (max-width: 480px) {
    .our-services-div {
        flex-direction: column;
    }

    .our-services-data-div {
        width: 100%;
        margin-bottom: 0;
    }

    .our-services-content-div {
        width: 100%;
    }

    .our-services-image-div {
        width: 100%;
        margin-bottom: 0;
    }
}