#commodity-container {
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 50;
}

#scroll-header-text {
    background-color: #0d0d0d;
    color: white;
    z-index: 10;
    padding: 8px;
    width: 12rem;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
}

/* Adjust the scroll container */
#scroll-container {
    overflow: hidden;
    width: 100%;
    display: flex;
    background-color: #0d0d0d;
}

#scroll-text {
    display: flex;
    gap: 2rem;
    padding: 8px;
    color: #fff;
    font-size: 1rem;
    margin-left: 1rem;
    white-space: nowrap;
    width: auto;
    animation: my-animation var(--scroll-duration, 150s) linear infinite;
}

/* Pause the scroll animation on hover */
#scroll-container:hover #scroll-text {
    animation-play-state: paused;
}

/* Scroll animation */
@keyframes my-animation {
    from {
        transform: translateX(2%);
    }
    to {
        transform: translateX(-100%);
    }
}

/* Mobile adjustments */
@media (max-width: 768px) {
    #scroll-header-text {
        width: 12rem; /* Reduce header width */
        font-size: 1rem; /* Smaller font size */
        padding: 6px; /* Adjust padding */
    }

    #scroll-text {
        font-size: 0.875rem; /* Smaller font size for mobile */
        gap: 1rem; /* Reduce gap between items */
        padding: 6px; /* Adjust padding */
        animation: my-animation var(--scroll-duration, 200s) linear infinite; /* Longer duration for smoother scrolling on mobile */
    }

    #commodity-container {
        padding: 0.5rem; /* Add smaller padding on mobile */
    }
}

/* Adjustments for tablets and larger devices */
@media (min-width: 768px) and (max-width: 1024px) {
    #scroll-header-text {
        width: 12rem;
        font-size: 1.1rem; /* Adjust font size slightly larger for tablet */
    }

    #scroll-text {
        font-size: 1rem; /* Increase font size for better visibility */
    }
}

/* Large screen adjustments */
@media (min-width: 1024px) {
    #scroll-header-text {
        width: 15rem; /* Increase width on larger screens */
        font-size: 1.2rem; /* Larger font size */
    }

    #scroll-text {
        font-size: 1.1rem; /* Slightly larger font size for large screens */
    }
}
